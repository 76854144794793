<template>
  <v-row justify="center" class="my-0 py-4">
    <v-col cols="12" md="10" lg="9" xl="8" class="white rounded-xl">
      <v-row class="ma-1">
        <v-row class="ma-0 d-flex mx-auto">
          <v-col>
            <p class="wc-h3 text-primary-variant">Eventos</p>
            <br />

            <FeesFilters
              :selectedRegion.sync="selectedRegion"
              :selectedBranch.sync="selectedBranch"
              :selectedBranches.sync="selectedBranches"
              :startDate.sync="startDate"
              :endDate.sync="endDate"
              @getGraphData="fetchData()"
            ></FeesFilters>

            <FeesMap
              :selectedRegion="selectedRegion"
              :selectedBranch="selectedBranch"
              :selectedBranches="selectedBranches"
            ></FeesMap>

            <EventsGraphs
              v-if="!loadingData"
              :graphData="graphData"
            ></EventsGraphs>

            <v-row v-else justify="center" class="my-0 py-4">
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-progress-circular
                  :model-value="loadingProgress"
                  :size="240"
                  :width="40"
                  color="primary"
                  indeterminate
                >
                  <span class="primary--text font-weight-black"
                    >{{ loadingProgress }} %</span
                  >
                </v-progress-circular>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import FeesFilters from "../fees/FeesFilters.vue";
import FeesMap from "../fees/FeesMap.vue";
import EventsGraphs from "./EventsGraphs.vue";
export default {
  name: "Fees",
  components: {
    FeesFilters,
    FeesMap,
    EventsGraphs,
  },
  data() {
    return {
      selectedRegion: "Nacional",
      selectedBranch: "",
      selectedBranches: [],
      startDate: "",
      endDate: "",
      graphData: {
        membersmenTickets: 0,
        memberswomenTickets: 0,
        submembersmenTickets: 0,
        submemberswomenTickets: 0,
        publicTickets: 0,
        totalRevenue: 0,
        womenRevenue: 0,
        menRevenue: 0,
        publicRevenue: 0,
        membersRevenue: 0,
        submembersRevenue: 0,
      },
      loadingData: false,
      loadingProgress: 0,
      processIndex: 0,
    };
  },
  methods: {
    setDefaultDates() {
      const today = new Date();
      const firstDay = new Date(today.getFullYear(), 0, 1);

      this.startDate = this.formatDate(firstDay);
      this.endDate = this.formatDate(today);
    },
    formatDate(date) {
      return date.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    async fetchData() {
      this.loadingData = true;
      this.processIndex++;

      const processIndex = this.processIndex;
      try {
        let page = 1;
        let hasNextPage = true;

        this.graphData = {
          membersmenTickets: 0,
          memberswomenTickets: 0,
          submembersmenTickets: 0,
          submemberswomenTickets: 0,
          publicTickets: 0,
          totalRevenue: 0,
          womenRevenue: 0,
          menRevenue: 0,
          publicRevenue: 0,
          membersRevenue: 0,
          submembersRevenue: 0,
        };

        while (hasNextPage && processIndex === this.processIndex) {
          const response = await this.axios({
            method: "POST",
            url: "/events/graphs",
            data: {
              startDate: this.startDate,
              endDate: this.endDate,
              region: this.selectedRegion,
              branch: this.selectedBranch,
              branches: this.selectedBranches,
              page: page,
            },
          });

          if (processIndex !== this.processIndex) {
            break;
          }

          for (const key in this.graphData) {
            if (key != "hasNextPage" && key != "totalPages") {
              this.graphData[key] += response.data[key];
            }
          }

          hasNextPage = response.data.hasNextPage;
          this.loadingProgress = parseInt(
            (page / response.data.totalPages) * 100
          );
          page++;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        if (processIndex === this.processIndex) {
          this.loadingData = false;
          this.loadingProgress = 0;
        }
      }
    },
  },
  created() {
    this.setDefaultDates();
    this.fetchData();
  },
};
</script>

<style></style>