<template>
  <v-row>
    <v-col cols="12" sm="6">
      <v-select
        v-model="selectedRegionComputed"
        solo
        dense
        class="busqueda my-0 py-0 pl-2 pr-5"
        placeholder="Nacional"
        :items="regionOptions"
        @change="handleRegionChange"
      ></v-select>
    </v-col>

    <v-col cols="12" sm="6" v-if="selectedRegionComputed === 'Local'">
      <v-select
        v-model="selectedBranchComputed"
        solo
        dense
        class="busqueda my-0 py-0 pl-2 pr-5"
        placeholder="Elige una opción"
        :items="branchesOptions"
        @change="handleBranchChange()"
      ></v-select>
    </v-col>
    <v-col cols="12" sm="6" v-if="selectedRegionComputed === 'Regional'">
      <v-select
        v-model="selectedBranchesComputed"
        chips
        solo
        dense
        multiple
        class="busqueda my-0 py-0 pl-2 pr-5"
        label="Selecciona secciones locales"
        :items="branchesOptions"
        @change="handleBranchChange()"
      ></v-select>
    </v-col>

    <v-row
      justify="center"
      class="my-0 py-4"
    >
      <v-col cols="12" sm="6" md="4" lg="3">
        <span @click="showStartDatePicker = true"></span>
        <v-menu
          v-model="showStartDatePicker"
          offset-y
          transition="scale-transition"
          max-width="290px"
          min-width="290px"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="startDateComputed"
              readonly
              v-on="on"
              filled
              rounded
              label="Fecha de inicio"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="startDateComputed"
            no-title
            locale="es"
            @change="showStartDatePicker = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <span @click="showEndDatePicker = true"></span>
        <v-menu
          v-model="showEndDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="endDateComputed"
              label="Fecha de Fin"
              readonly
              v-on="on"
              filled
              rounded
            >
            </v-text-field>
          </template>
          <v-date-picker
            locale="es"
            v-model="endDateComputed"
            no-title
            @input="showEndDatePicker = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="pt-5">
        <v-btn color="primary" @click="applyDates()">Aplicar fechas</v-btn>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "FeesFilters",
  props: {
    selectedRegion: {
      type: String,
      required: true,
    },
    selectedBranch: {
      type: String,
      required: true,
    },
    selectedBranches: {
      type: Array,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      regionOptions: ["Nacional", "Local", "Regional"],
      branchesOptions: [],
      showStartDatePicker: false,
      showEndDatePicker: false,
    };
  },
  computed: {
    selectedRegionComputed: {
      get() {
        return this.selectedRegion;
      },
      set(value) {
        this.$emit("update:selectedRegion", value);
      },
    },
    selectedBranchComputed: {
      get() {
        return this.selectedBranch;
      },
      set(value) {
        this.$emit("update:selectedBranch", value);
      },
    },
    selectedBranchesComputed: {
      get() {
        return this.selectedBranches;
      },
      set(value) {
        this.$emit("update:selectedBranches", value);
      },
    },
    startDateComputed: {
      get() {
        //convert from day/month/year to day-month-year 
        let date = this.startDate.split("/");
        return `${date[2]}-${date[1]}-${date[0]}`;
      },
      set(value) {
        let date = value.split("-");
        this.$emit("update:startDate", `${date[2]}/${date[1]}/${date[0]}`);
      },
    },
    endDateComputed: {
      get() {
        //convert from day/month/year to day-month-year 
        let date = this.endDate.split("/");
        return `${date[2]}-${date[1]}-${date[0]}`;
      },
      set(value) {
        let date = value.split("-");
        this.$emit("update:endDate", `${date[2]}/${date[1]}/${date[0]}`);
      },
    },
  },
  methods: {
    ...mapActions("branches", ["getBranches", "fetchChapterByName"]),
    handleBranchChange() {
      this.$emit("getGraphData");
    },
    handleRegionChange() {
      this.resetBranches();
      if (this.selectedRegionComputed === "Nacional") {
      this.$emit("getGraphData");
      }
    },
    resetBranches() {
      this.$emit("update:selectedBranch", "");
      this.$emit("update:selectedBranches", []);
    },
    async fetchBranches() {
      try {
        const response = await this.getBranches();

        if (response.status === 200 && response.branches) {
          this.branchesOptions = response.branches.map((branch) => ({
            text: branch.company,
            value: branch._id,
          }));
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    },
    applyDates() {
      this.$emit("getGraphData");
    },
  },
  created() {
    this.fetchBranches();
  },
};
</script>

<style>
</style>